<template>
  <product-price-worksheet />
</template>

<script>
import ProductPriceWorksheet from '@/components/pages/product/ProductPriceWorksheet'

export default {
  components: {
    ProductPriceWorksheet
  }
}
</script>

ProductPriceWorksheet
